import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { useHistory } from 'react-router-dom'

import { Typography, palette } from '../../atoms'
import Overlay from '../atoms/Overlay'
import Stack from '../atoms/Stack'
import {
  Button,
  CircularProgress,
  Grid,
  Link,
  createStyles,
  makeStyles
} from '@material-ui/core'

import { useAsyncFn } from '../../hooks'

import { handleElementShown, handleUserClick } from '../../api/Analytics'
import { apiCreateBlog } from '../../api/Blog'

import {
  AdTextType,
  adHeaders,
  adSubHeaders
} from '../../interfaces/AdvertisingText'
import { ContentGenerationType } from '../../interfaces/ContentGeneration'

import { generationTypeToAdTextMap } from '../../utils/Interfaces'
import snackbar from '../../utils/Snackbar'
import { useCatalogueTemplateContext } from '../../utils/context/CatalogueTemplateContext'
import { ToolCardProvider } from '../../utils/context/ToolCardContext'
import { useUserContext } from '../../utils/context/UserContext'

import {
  DEFAULT_CARD_GROUPS,
  HOMEPAGE_CARD_GROUPS_WITH_SEO_REWRITER,
  IToolCardGroup,
  PAPA_HOMEPAGE_CARD_GROUPS
} from './CardGroups'
import { ToolCardGroup, ToolCardGroupBody } from './ToolCardGroup'
import { ToolLibraryModal } from './ToolLibraryModal'
import blogIcon from './assets/v2/blog_article.svg'
import hypoChatIcon from './assets/v2/hypochat.svg'
import hypodoc_logo from './assets/v2/hypodoc.svg'
import catalogIcon from './assets/v2/product_description_rewriter.svg'
import { AUTOMAPPER_CARD_ID } from './cards/AutomapperToolCard'
import { REWRITER_CARD_ID } from './cards/ProductDescriptionRewriterCard'
import { SuggestionCard } from './cards/SuggestionCard'
import { AskAiAnythingCardWithMetadata } from './cards/TemplateCard'
import { marketingRoutes, toolLogos } from './mappings'
import { useStore } from './useStore'

interface ToolLibraryProps {
  /**
   * A list of card groups to display.
   * If not provided, the default list of card groups will be used.
   */
  cardGroups?: readonly IToolCardGroup[]
  onOpenChat?: () => void
  width?: string
  isToolRecommendationEnabled?: boolean
  recommendedTools?: ContentGenerationType[]
}

const useStyles = makeStyles((theme) =>
  createStyles({
    mainCard: {
      transition: 'all 0.2s ease-out',
      '&:hover': {
        boxShadow: '0px 4px 8px rgba(145 158 171 / 16%)'
      },
      boxShadow: theme.shadows[4],
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(2, 2),
      backgroundColor: theme.palette.common.white,
      width: '100%',
      cursor: 'pointer',
      flexDirection: 'row',
      flexGrow: 1,
      alignItems: 'center'
    }
  })
)

const SUGGESTION_SECTION_ID = 'suggestion'
const SEARCH_RESULT_SECTION_ID = 'search-results'

const ToolLibrary: React.FC<ToolLibraryProps> = (props) => {
  const {
    cardGroups = DEFAULT_CARD_GROUPS,
    onOpenChat = () => {},
    width,
    isToolRecommendationEnabled = false,
    recommendedTools = []
  } = props

  const { search } = useStore()
  const { user } = useUserContext()
  const isPapaPlanUser = !!user?.service_configs?.feature_flags?.is_papa_user
  const hasRewriteForSeoTool = !!user?.service_configs?.feature_flags
    ?.enable_rewrite_for_seo
  const isCatalogueTemplateEnabled = !!user?.service_configs?.feature_flags
    ?.enable_catalogue_templates
  const showBulkRewriterCard = !!user?.service_configs?.feature_flags
    ?.show_bulk_rewriter_card
  const showAutomapperCard = !!user?.service_configs?.feature_flags
    ?.enable_automapper

  const filteredCardGroups = useMemo(() => {
    const customCardGroups = isPapaPlanUser
      ? PAPA_HOMEPAGE_CARD_GROUPS
      : hasRewriteForSeoTool
      ? HOMEPAGE_CARD_GROUPS_WITH_SEO_REWRITER
      : cardGroups
    return customCardGroups.map(({ id, title, subTitle, cards }) => {
      const filteredCards = cards.filter((card) => {
        if (card.metadata.id === REWRITER_CARD_ID) {
          return isCatalogueTemplateEnabled && showBulkRewriterCard
        }
        if (card.metadata.id === AUTOMAPPER_CARD_ID) {
          return showAutomapperCard
        }
        return true
      })

      return {
        id,
        title,
        subTitle,
        cards: filteredCards
      }
    })
  }, [
    cardGroups,
    hasRewriteForSeoTool,
    isCatalogueTemplateEnabled,
    isPapaPlanUser,
    showAutomapperCard,
    showBulkRewriterCard
  ])

  const suggestionCard = useMemo(
    () => (
      <ToolCardProvider value={{ sectionId: SUGGESTION_SECTION_ID }}>
        <SuggestionCard onClick={onOpenChat} />
      </ToolCardProvider>
    ),
    [onOpenChat]
  )

  // Shown when no search string is entered
  const baseCardGroups = useMemo(() => {
    const finalisedCardGroups = filteredCardGroups.map(
      ({ id, title, subTitle, cards }) => {
        const sectionId = id.toLowerCase().replace(/& /g, '').replace(/ /g, '-')

        const filteredCards = cards.filter(
          (card) =>
            card.metadata.id !== REWRITER_CARD_ID ||
            (isCatalogueTemplateEnabled && showBulkRewriterCard)
        )

        return (
          <ToolCardProvider value={{ sectionId: sectionId }}>
            <ToolCardGroup
              key={id}
              id={id}
              title={title}
              subTitle={subTitle}
              cards={filteredCards}
            />
          </ToolCardProvider>
        )
      }
    )
    return [...finalisedCardGroups, suggestionCard]
  }, [
    filteredCardGroups,
    suggestionCard,
    isCatalogueTemplateEnabled,
    showBulkRewriterCard
  ])

  const filteredCards = useMemo(() => {
    if (search) {
      const cardsWithMetadata = filteredCardGroups.flatMap(
        (group) => group.cards
      )

      // Remove cards that may be duplicated across groups
      const uniqueCards = cardsWithMetadata.filter(
        (cardWithMetadata, index) => {
          return (
            cardsWithMetadata.findIndex(
              (other) => other.metadata.id === cardWithMetadata.metadata.id
            ) === index
          )
        }
      )

      const matchedCards = uniqueCards
        .filter((cardWithMetadata) =>
          cardWithMetadata.metadata.searchTerms?.some((term) =>
            term.toLowerCase().includes(search.toLowerCase())
          )
        )
        .map(({ card }) => card)

      if (matchedCards.length > 0) {
        return (
          <ToolCardProvider value={{ sectionId: SEARCH_RESULT_SECTION_ID }}>
            <ToolCardGroupBody cards={[...matchedCards, suggestionCard]} />
          </ToolCardProvider>
        )
      } else {
        return (
          <ToolCardProvider value={{ sectionId: SEARCH_RESULT_SECTION_ID }}>
            <ToolCardGroupBody
              cards={[AskAiAnythingCardWithMetadata.card, suggestionCard]}
            />
          </ToolCardProvider>
        )
      }
    }
    return baseCardGroups
  }, [filteredCardGroups, search, suggestionCard, baseCardGroups])

  return (
    <Stack
      direction="column"
      spacing={8}
      style={
        width
          ? {
              width: width
            }
          : {}
      }
    >
      {isToolRecommendationEnabled && (
        <SuggestionSection
          recommendedTools={recommendedTools}
          onOpenChat={onOpenChat}
          cardGroups={cardGroups}
          showBulkRewriterCard={
            showBulkRewriterCard && isCatalogueTemplateEnabled
          }
        />
      )}
      {filteredCards}
    </Stack>
  )
}

interface SuggestionSectionProps {
  recommendedTools: ContentGenerationType[]
  onOpenChat: () => void
  cardGroups: readonly IToolCardGroup[]
  showBulkRewriterCard: boolean
}

const SuggestionSection: React.FC<SuggestionSectionProps> = (
  props: SuggestionSectionProps
) => {
  const {
    recommendedTools,
    onOpenChat,
    cardGroups,
    showBulkRewriterCard
  } = props
  const [templateModalOpen, setTemplateModalOpen] = useState(false)

  const onSeeAllContent = useCallback(() => {
    setTemplateModalOpen(true)
    handleUserClick(
      'see-all-content',
      window.location.pathname,
      'suggested-for-you'
    )
  }, [])

  return (
    <Stack>
      <ToolLibraryModal
        open={templateModalOpen}
        onClose={() => setTemplateModalOpen(false)}
        onOpenChat={onOpenChat}
        cardGroups={cardGroups}
      />
      <Stack
        justifyContent="space-between"
        alignItems="center"
        flexDirection="row"
      >
        <Typography variant="heading3" style={{ color: palette.gray[900] }}>
          Suggested for you
        </Typography>
        <Button variant="text" onClick={onSeeAllContent}>
          <Typography
            variant="paragraph2Bold"
            style={{ color: palette.primary[600] }}
          >
            See all
          </Typography>
        </Button>
      </Stack>
      <Stack spacing={1.5} flexDirection="row" marginTop={2}>
        <Grid container spacing={2}>
          {recommendedTools.map((tool, i) => (
            <SuggestionForYouCard
              tool={tool}
              key={i}
              showBulkRewriterCard={showBulkRewriterCard}
            />
          ))}
        </Grid>
      </Stack>
    </Stack>
  )
}

interface SuggestionForYouCardProps {
  tool: ContentGenerationType
  key: number
  showBulkRewriterCard: boolean
}

const SuggestionForYouCard: React.FC<SuggestionForYouCardProps> = (
  props: SuggestionForYouCardProps
) => {
  const { tool, key, showBulkRewriterCard } = props
  const history = useHistory()
  const classes = useStyles()

  const iconSrc = useMemo(() => {
    if (tool === ContentGenerationType.product_descriptions) {
      return catalogIcon
    } else if (tool === ContentGenerationType.blog_article) {
      return blogIcon
    } else if (tool === ContentGenerationType.chat_message) {
      return hypoChatIcon
    } else if (tool === ContentGenerationType.chat_message_file_upload) {
      return hypodoc_logo
    } else {
      const adTextType = generationTypeToAdTextMap?.[tool] ?? AdTextType.google
      return toolLogos[adTextType as AdTextType]
    }
  }, [tool])

  const title = useMemo(() => {
    if (tool === ContentGenerationType.product_descriptions) {
      return `${
        showBulkRewriterCard ? 'Rewrite ' : ''
      } Product Descriptions (Bulk)`
    } else if (tool === ContentGenerationType.blog_article) {
      return 'Blog Article'
    } else if (tool === ContentGenerationType.chat_message) {
      return 'HypoChat'
    } else if (tool === ContentGenerationType.chat_message_file_upload) {
      return 'HypoDoc'
    } else {
      const adTextType = generationTypeToAdTextMap?.[tool] ?? AdTextType.google
      return adHeaders?.[adTextType as AdTextType] ?? 'Ad text'
    }
  }, [tool, showBulkRewriterCard])

  const subtitle = useMemo(() => {
    if (tool === ContentGenerationType.product_descriptions) {
      return showBulkRewriterCard
        ? 'Enhance thousands of existing product descriptions in bulk.'
        : 'Create thousands of SEO optimized product descriptions in bulk.'
    } else if (tool === ContentGenerationType.blog_article) {
      return 'Write SEO optimized blog articles with factual real time research.'
    } else if (tool === ContentGenerationType.chat_message) {
      return 'Real time web AI chat for research brainstorm ideas, multi-purpose content creation.'
    } else if (tool === ContentGenerationType.chat_message_file_upload) {
      return 'Upload a technical document and ask AI to analyze, answer questions, summarize and more.'
    } else {
      const adTextType = generationTypeToAdTextMap?.[tool] ?? AdTextType.google
      return adSubHeaders?.[adTextType as AdTextType] ?? 'Ad text'
    }
  }, [tool, showBulkRewriterCard])

  useEffect(() => {
    const elementId = title.toLowerCase().replace(/ /g, '-')
    handleElementShown(elementId, 'suggested-for-you', window.location.pathname)
  }, [title])

  const { rewriterTemplateId = '' } = useCatalogueTemplateContext() || {}

  const href = useMemo(() => {
    if (tool === ContentGenerationType.product_descriptions) {
      // TODO: Update this to include the catalogue template id params
      // Will update this in the next iteration
      return showBulkRewriterCard
        ? `/product-descriptions?openCreateCatalogueModal=1&catTemplateId=${rewriterTemplateId}`
        : '/product-descriptions?openCreateCatalogueModal=1'
    } else if (
      ![
        ContentGenerationType.product_descriptions,
        ContentGenerationType.blog_article,
        ContentGenerationType.chat_message,
        ContentGenerationType.chat_message_file_upload,
        ContentGenerationType.chat_message
      ].includes(tool)
    ) {
      const adTextType = generationTypeToAdTextMap?.[tool] ?? AdTextType.google
      if (adTextType === AdTextType.meta_desc) return 'collections/meta_desc'

      let _href = `${marketingRoutes[adTextType]}`

      const isHidden = 1
      _href += `?isHidden=${isHidden}`

      return _href
    }
  }, [tool, showBulkRewriterCard, rewriterTemplateId])

  const _onClick = useCallback(async () => {
    handleUserClick(
      `home-${title.toLowerCase().split(' ').join('-')}`,
      window.location.pathname,
      'suggested-for-you'
    )
    if (tool === ContentGenerationType.blog_article) {
      try {
        const blog = await apiCreateBlog()

        let queryParams = `?blogId=${blog.blogId}`

        history.push({
          pathname: '/blog-post',
          search: queryParams
        })
      } catch (_error) {
        snackbar.show(
          'Failed to create blog. Please contact our support team',
          {
            variant: 'error'
          }
        )
      }
    } else if (
      tool === ContentGenerationType.chat_message_file_upload ||
      tool === ContentGenerationType.chat_message
    ) {
      history.push('/hypochat')
    }
  }, [history, tool, title])

  const [{ loading: loadingClick }, onClick] = useAsyncFn(_onClick, [_onClick])

  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={6}
      key={key}
      onClick={loadingClick ? undefined : onClick}
    >
      <Link
        href={href}
        style={{ textDecoration: 'none', display: 'flex', height: '100%' }}
      >
        <Stack
          className={classes.mainCard}
          spacing={1.5}
          style={{ position: 'relative' }}
        >
          {loadingClick && (
            <Overlay open>
              <CircularProgress size={20} color="inherit" />
            </Overlay>
          )}
          {!loadingClick && (
            <Stack>
              <img
                src={iconSrc}
                alt="tool-icon"
                style={{ width: '40px', height: '40px' }}
              />
            </Stack>
          )}
          <Stack textAlign="left" spacing={0.5}>
            <Typography
              variant="paragraph2Bold"
              style={{
                color: palette.gray['900']
              }}
            >
              {title}
            </Typography>
            <Typography
              variant="captionReg"
              style={{
                color: palette.gray['600']
              }}
            >
              {subtitle}
            </Typography>
          </Stack>
        </Stack>
      </Link>
    </Grid>
  )
}

export default ToolLibrary
