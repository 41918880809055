import palette from '@hypotenuse/common/src/atoms/Colors'

import {
  GenerationFilterType,
  GenerationStatusFilterKey,
  GenerationStatusFilterLabel
} from '@hypotenuse/common/src/interfaces/Products'
import { Tour, TourType } from '@hypotenuse/common/src/interfaces/Tutorial'
import { SERVER } from '@hypotenuse/common/src/utils/Constants'

import { ProductTabLabel, TabKey } from '../components/utils/Interfaces'

// ===== THEME, STYLES, & COLORS =====
export const COLOR_GRADIENTS_HEX_CODE = [
  '#6B00C0',
  '#5B30C0',
  '#4C5FC1',
  '#3C8FC1',
  '#2CBEC1'
]

export const CONTENT_CARDS_BOX_SHADOWS =
  'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px'
export const HOVERED_CONTENT_CARD_BOX_SHADOW =
  '0 13px 16px -5px rgba(145 158 171 / 16%)'
export const SOFT_BOX_SHADOW =
  'rgb(145 158 171 / 30%) 0px 1px 1px, rgb(145 158 171 / 10%) 0px 1px 2px'
export const BUTTON_BOX_SHADOW =
  'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 20%) 0px 1px 3px -1px'

// ===== PREVIOUS INPUT MAPPINGS =====
export const PREVIOUS_INPUT_MAPPINGS = 'previousInputMappings'

// ===== HELPHERO =====
export const HELPHERO_TUTORIAL_TOUR: Tour = {
  // This is for Tutorial Tour
  id: 'oAgQa32z8li',
  name: 'tutorial_tour',
  type: TourType.helphero
}
// This tour id is set to trigger when user has created five products
// We want to trigger this only when user import products one by one
export const HELPHERO_GENERATE_FIVE_PRODUCTS_TOUR_ID = 'zp0yLcwtfH'
export const GENERATE_WITHOUT_TAGS_DIALOG_ID =
  'generate-description-without-tags'
export const MARK_AS_DONE_WITHOUT_SELECTING_DESCRIPTIONS_DIALOG_ID =
  'mark-as-done-without-selecting-descriptions'
export const DELETE_PRODUCT_DIALOG_ID = 'delete-product'

export const HELPHERO_APP_ID = 'zYiL6odmzMZ'
export const TUTORIAL_VERSION = '1'

// ===== MISC CONSTANTS =====
export const PRODUCT_TITLE_MAX_CHARS = 500
export const PRODUCT_BRAND_MAX_CHARS = 1500
export const PRODUCT_TAGS_MAX_CHARS = 15_000
export const DOWNVOTE_DEBOUNCE_TIME = 500

export const DEFAULT_PLAN_NAME = 'Standard Plan'
export const STAGE = process.env.REACT_APP_STAGE
export const IS_PRODUCTION = STAGE === 'prod'
export const IS_STAGING = STAGE === 'staging'
export const IS_DEPLOYED = IS_PRODUCTION || IS_STAGING
export const PREMIUM_LEGACY_LOGOUT_PATH = `${SERVER}/legacy-logout`
/**
 * Login URL for the legacy cookie-based auth scheme.
 * The page must be reloaded for this URL to work.
 */
export const PREMIUM_LEGACY_LOGIN_URL = `${SERVER}/legacy-login`
/**
 * URL to redirect to after login.
 * This should handle further redirects as well as post-login actions.
 */
export const PREMIUM_LEGACY_POST_LOGIN_URL = `${SERVER}/auth`
export const BACKEND_API_DATA_DOCS_URL = `${SERVER}/api/docs/data`

export const AMPLITUDE_API_KEY =
  process.env.REACT_APP_AMPLITUDE_API_KEY || 'd6edac4c178c25eeb074e8cc5e50d9db'

// ===== POSTHOG =====
export const POSTHOG_API_KEY =
  process.env.REACT_APP_POSTHOG_API_KEY ||
  'phc_h6H9p7O9SGRQHpFV6fdaI0PPwppCuPRpFfEnFOvVPWw'
export const PUBLIC_POSTHOG_URL =
  process.env.REACT_APP_PUBLIC_POSTHOG_HOST || 'https://us.i.posthog.com'

// ===== G2 =====
export const G2_LINKED_ITEM_ID_PLACEHOLDER = 'g2-linked-item-id-placeholder'

// ===== GOOGLE TAG MANAGER CONSTANTS =====
/**
 * GTM Container ID, should be like 'GTX-XXXXXXX'
 */
export const GTM_CONTAINER_ID = 'GTM-5XGSDVT'
/**
 * GTM environment name — used for previewing GTM behavior in the GTM tag assistant (https://tagassistant.google.com)
 * For production, leave this as undefined or empty string.
 * Get this from the `gtm_preview` query param in the GTM code snippet shown on the GTM console.
 */
export const GTM_PREVIEW =
  process.env.REACT_APP_GTM_PREVIEW || IS_PRODUCTION
    ? undefined // undefined means use the default environment (live)
    : IS_STAGING
    ? 'env-18' // Staging env
    : 'env-17' // Local dev env
/**
 * GTM environment auth — used for previewing GTM behavior in the GTM tag assistant (https://tagassistant.google.com)
 * For production, leave this as undefined or empty string.
 * Get this from the `gtm_auth` query param in the GTM code snippet shown on the GTM console.
 * This is required if you specify GTM_PREVIEW.
 */
export const GTM_AUTH =
  process.env.REACT_APP_GTM_AUTH || IS_PRODUCTION
    ? undefined // undefined means use the default environment (live)
    : IS_STAGING
    ? 'PjJSQO-ly_cKnQVv9_X_KQ' // Staging env
    : '7ylakmxl28cED4qAqY8hjQ' // Local dev env

// ===== AUTH =====
/**
 * NOTE: Make sure to keep this frontend flag in sync with the corresponding flag on the backend.
 */
export const USE_JWT_AUTH = false
/**
 * Auth page routes for the new JWT-based auth scheme.
 */
export const LOGOUT_PAGE_PATH = '/logout'
/**
 * Path to the login page.
 */
export const LOGIN_PAGE_PATH = '/login'
/**
 * Path to the post-login handler page.
 */
export const POST_LOGIN_PAGE_PATH = '/post-login'
/**
 * Path to generic auth error page
 */
export const AUTH_ERROR_PAGE_PATH = '/auth-error'
/**
 * Relative path to redirect to after logging out.
 * This currently only serves to keep us on the same domain post-logout,
 * but could have other uses in the future.
 */
export const LOGOUT_REDIRECT_PATH = '/'
/**
 * Path to email verification page. Users who have not yet verified their email
 * will be redirected here upon login.
 */
export const EMAIL_VERIFICATION_PAGE_PATH = '/email-verification'
/**
 * Page where blocked users are redirected to.
 */
export const DISABLED_ACCOUNT_REDIRECT_PATH = '/account-disabled'
/**
 * Page where deleted users are redirected to.
 */
export const DELETED_ACCOUNT_REDIRECT_PATH = '/account-deleted'

/**
 * Page where shadow banned users are redirected to.
 */
export const SHADOW_BANNED_ACCOUNT_REDIRECT_PATH = '/trial-ended'

/**
 * Path to the pricing page.
 */
export const PRICING_PAGE_PATH = '/settings/pricing'

// ===== AUTH0 =====
/**
 * Client ID of this Single Page App on Auth0
 */
export const AUTH0_CLIENT_ID =
  process.env.REACT_APP_AUTH0_CLIENT_ID ?? 'p17g6OnmYjBjwg3TQVQQv441ykFtNVav'
/**
 * Auth0 Tenant domain
 */
export const AUTH0_DOMAIN =
  process.env.REACT_APP_AUTH0_DOMAIN ?? 'dev-f63lapj1.eu.auth0.com'
/**
 * The Auth0 identifier of the backend API. We use this to fetch an access token to authenticate with the backend.
 */
export const BACKEND_API_AUTH0_AUDIENCE =
  process.env.REACT_APP_BACKEND_API_AUTH0_AUDIENCE ??
  'https://app.hypotenuse.ai/'
/**
 * Additional OAuth scopes to request when authenticating with Auth0.
 * The default scopes ('openid profile email') are included automatically.
 */
export const AUTH0_AUTH_SCOPE = ''

// ===== SUPERTOKENS =====
export const SUPERTOKENS_API_BASE_PATH = '/supertokens'
export const SUPERTOKENS_UI_BASE_PATH = '/auth-dashboard'

// ===== STRIPE KEYS =====
export const STRIPE_TEST_PUBLISHABLE_KEY = IS_PRODUCTION
  ? 'pk_live_51HXNGqLMJlm3MgJtV7K9g1V57Wor4fKpzmXNN3puMJCcE8TOMFmndNSgGGps7VborrZXy5IyKA04banxZl1Udr4n00hk8UTmLT'
  : 'pk_test_51HXNGqLMJlm3MgJtbs87HbD0GkBvnH7y1PbwEIz8WmEDoO5FfBqRpqrgkDClyZi4HcE85WC6OhaWAwIA0xlKjXvr005FBheGHn'

// ===== HELP SCOUT BEACON =====
export const HELPSCOUT_BEACON_ID = '5e285013-625c-44db-affd-d83c65549d2a'

// ===== PAPERCUPS CHAT SUPPORT =====
export const PAPERCUPS_ACCOUNT_ID = 'f3aab9b1-e88f-4a8b-bd3a-2b8ee848ee75'
export const PAPERCUPS_CHAT_TITLE = 'Welcome to Hypotenuse AI'
export const PAPERCUPS_CHAT_SUBTITLE = 'Ask us anything 😊'
export const PAPERCUPS_CHAT_PLACEHOLDER = 'Start typing...'
export const PAPERCUPS_CHAT_GREETING = 'Hi there! What can I help you with?'
export const PAPERCUPS_CHAT_COLOR = palette.primary[500]
export const PAPERCUPS_BASE_URL = 'https://app.papercups.io'

// ===== INTERCOM CHAT SUPPORT =====
export const INTERCOM_APP_ID = 'z8egkvj9'

// ===== FINGERPRINT =====
export const FINGERPRINT_PRO_PUBLIC_API_KEY = 'Tyc4iXpxgRla7rPzvL1J'
export const FINGERPRINT_ENDPOINT = 'https://uxu7eeWe.hypotenuse.ai'

// ===== SENTRY =====
export const SENTRY_DSN =
  'https://9ecf08ec879449dbb6d297a34abb2b02@o489922.ingest.sentry.io/5745287'
/**
 * Trace sample rate for Sentry. This is a number between 0 and 1, where 1 means
 * all traces will be sent to Sentry.
 */
export const SENTRY_TRACE_SAMPLE_RATE = 0.01

// ===== GOOGLE ANALYTICS =====
export const GOOGLE_ANALYTICS_TRACK_CODE = 'UA-159925479-2'

// ===== LOGROCKET =====
export const LOGROCKET_APP_ID_1 = 'd5hj4i/premium-prod'
export const LOGROCKET_APP_ID_2 = 'bippsm/premium-prod'
export const LOGROCKET_APP_ID = LOGROCKET_APP_ID_1 // To be switched out when running close to LogRocket limits

// ===== Semantic UI Popup Overflow Prevention
// Needed when parent has overflow property: https://github.com/Semantic-Org/Semantic-UI-React/issues/3725
export const SEMANTIC_POPUP_OVERFLOW_PREVENTION_PROPS = {
  preventOverflow: {
    boundariesElement: 'offsetParent'
  }
}

// ===== BULK MODALS =====
export enum BulkDescribeModals {
  generate,
  viewAllJobs,
  failed,
  completedWithErrors,
  enrichmentSourceSelection
}

export const DEFAULT_CATALOGUE_NAME = 'My first catalog'

// ===== TABS & FILTERING =====
export const TAB_VALUES = [
  {
    key: TabKey.new,
    label: ProductTabLabel[TabKey.new],
    loading: false
  },
  {
    key: TabKey.reviewing,
    label: ProductTabLabel[TabKey.reviewing],
    loading: false
  },
  {
    key: TabKey.saved,
    label: ProductTabLabel[TabKey.saved],
    loading: false
  },
  {
    key: TabKey.all,
    label: ProductTabLabel[TabKey.all],
    loading: false
  }
]

// Note that BASE_TAB_VALUES is required to form tabs for when enable_review = false for a user
export const BASE_TAB_VALUES = TAB_VALUES.filter(
  (tab) => tab.key !== TabKey.reviewing
)

export const GENERATION_STATUS_FILTER_VALUES = [
  {
    key: GenerationStatusFilterKey.all,
    label: GenerationStatusFilterLabel[GenerationStatusFilterKey.all]
  },
  {
    key: GenerationStatusFilterKey.generation_error,
    label:
      GenerationStatusFilterLabel[GenerationStatusFilterKey.generation_error]
  },
  {
    key: GenerationStatusFilterKey.generated,
    label: GenerationStatusFilterLabel[GenerationStatusFilterKey.generated]
  },
  {
    key: GenerationStatusFilterKey.not_generated,
    label: GenerationStatusFilterLabel[GenerationStatusFilterKey.not_generated]
  }
]

export const ALL_GENERATION_FILTERS_TYPES = [
  GenerationFilterType.descriptions,
  GenerationFilterType.seo_keywords,
  GenerationFilterType.enrich_from_image,
  GenerationFilterType.enrich_from_web,
  GenerationFilterType.enrich_from_url,
  GenerationFilterType.enrich_from_upc
]

export const ALL_FILTER_STATUSES = [
  // Do not include "all" as these statuses will be multi-select
  GenerationStatusFilterKey.not_generated,
  GenerationStatusFilterKey.generated,
  GenerationStatusFilterKey.generation_error
]

export const DEFAULT_IMPORT_HEADERS = {
  title: 'title',
  imgSrc: 'imgSrc',
  multiImgSrcs: 'multiImgSrcs',
  brand: 'brand',
  tags: 'tags',
  seoKeywords: 'seoKeywords',
  rootKeyword: 'rootKeyword',
  retailer: 'retailer',
  enrichUrl: 'enrichUrl',
  sku: 'sku',
  upc: 'upc'
}

export const EXISTING_DETAILS_IMPORT_HEADERS = {
  existingDescription: 'existingDescription',
  existingBullets: 'existingBullets'
}

export const DEFAULT_PAYMENT_FAILURE_MESSAGE =
  'Your payment method was declined, please retry your purchase.'

/**
 * The percentage threshold of the number of words generated for sidebar to show
 */
export const SIDEBAR_WORD_USAGE_THRESHOLD_PERCENT = 80

// ====== Referrals ======

export const DEFAULT_REFERRAL_WORDS = 15_000
export const DEFAULT_LINK_SHARING_WORDS = 9_000

// ====== Feature Flags ======

// ====== Knowledge Base ======

// ====== Shopify Billing ======
export const ShopifyBillingPlanIds = [
  'shopify-test-monthly',
  'shopify-test-yearly'
]

export const ShopifyPlanListId = 'shopify-test'
