import { loadStripe } from '@stripe/stripe-js'
import moment from 'moment'

import { userLocale } from '@hypotenuse/common/src/components/assetsDisplay/utils'
import {
  CatalogueFilter,
  InputColumnNamesCSV,
  ProductSearchQuery
} from '@hypotenuse/common/src/interfaces/Products'
import { PlatformType } from '@hypotenuse/common/src/utils/Interfaces'

import { PremiumPlanTierWithPlanType } from '../hooks/usePremiumPlanTier'

import { handleCreateStripeCheckoutSession } from '../api/Billing'

import {
  PlanBillingPeriod,
  PlanType,
  PremiumPlanTier,
  User
} from '../components/utils/Interfaces'

import {
  HELPHERO_TUTORIAL_TOUR,
  PREVIOUS_INPUT_MAPPINGS,
  STRIPE_TEST_PUBLISHABLE_KEY
} from './Constants'

export const typingAnimationInputField = async (
  selector: string,
  text: string,
  speed: number = 20
) => {
  const element: HTMLInputElement = document.querySelector(
    selector
  ) as HTMLInputElement

  for (let i = 0; i < text.length; i++) {
    element.value += text[i]
    await new Promise((r) => setTimeout(r, speed))
  }
}

export const getTimeElapsedInSeconds = (datetime: string | Date) => {
  const inputDate = new Date(datetime)
  // Javascript iso string is different than Python, so need to trim the Z at the end.
  const datenow = new Date(new Date().toISOString().replace('Z', ''))
  return (datenow.getTime() - inputDate.getTime()) / 1000
}

export const convertEpochTimeToDate = (timeElapsedInSeconds: number) => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]
  const date = new Date(timeElapsedInSeconds * 1000)
  return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`
}

export const toTitleCase = (text: string): string => {
  return text
    .split(' ')
    .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
    .join(' ')
}

/**
 * Simple regex-based check for whether a string is an email address
 */
export const isStringEmailAddress = (s: string): boolean => {
  return /^\S+@\S+\.\S+$/.test(s)
}

export const extractNameFromEmailAddress = (username: string) => {
  // If the username is an email address, extract just the part before the @
  return toTitleCase(
    isStringEmailAddress(username) ? username.split('@', 2)[0] : username
  )
}

export const redirectWithHiddenButton = (url: string) => {
  const link = document.createElement('a')
  link.href = url

  // Append to html link element page
  document.body.appendChild(link)

  // Start download
  link.click()

  // Clean up and remove the link
  document.body.removeChild(link)
}

/**
 * Construct a ProductSearchQuery given a CatalogueFilter
 * @param {CatalogueFilter} filter Filter without pagination
 * @param {number} page Page number
 * @param {number} step Number of items per page
 * @returns {ProductSearchQuery} ProductSearchQuery (including pagination)
 */
export function makeQueryFromFilter(
  filter: CatalogueFilter,
  page: number,
  step: number = 10
): ProductSearchQuery {
  return {
    ...filter,
    start: (page - 1) * step,
    end: page * step
  }
}

export const getIsTutorialComplete = (user: User): boolean => {
  const completedToursIds = user.completedToursIds
  return (
    // completedToursIds === undefined ||
    completedToursIds.includes(HELPHERO_TUTORIAL_TOUR.id)
  )
}

export const setPreviousInputMappings = (
  mappings: InputColumnNamesCSV
): void => {
  localStorage.setItem(PREVIOUS_INPUT_MAPPINGS, JSON.stringify(mappings))
}

export const getPreviousInputMappings = (): InputColumnNamesCSV => {
  return (
    JSON.parse(
      localStorage.getItem(PREVIOUS_INPUT_MAPPINGS) ??
        ((null as unknown) as string)
    ) ?? undefined
  )
}

/**
 * This function capitalizes the start of every word in the sentence.
 * Example: 'Hello woRld' -> 'Hello WoRld'
 */
export const capitalize = (text: string): string =>
  text.replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()))

/**
 * Simple hash function is taken from https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
 */
export const hashText = (text: string): number =>
  Math.abs(
    text.split('').reduce((a, b) => {
      a = (a << 5) - a + b.charCodeAt(0)
      return a & a
    }, 0)
  )

/**
 * This function redirects user to Stripe Checkout Session and is shared in both Pricing and Billing page
 * @param plan_id
 */
export const redirectToStripeCheckoutSession = async (plan_id: string) => {
  const stripe = await loadStripe(STRIPE_TEST_PUBLISHABLE_KEY)
  const sessionId = await handleCreateStripeCheckoutSession(plan_id)
  stripe?.redirectToCheckout({ sessionId: sessionId })
}

/**
 * This function returns a predicate to filter out any paid or active plan tiers.
 * @param activePlanTier The User's Active Plan Tier to filter
 * @returns A predicate that checks whether a plan tier is free or active.
 */
export const paidAndActivePlansPredicate = (
  activePlanTier: PremiumPlanTier | undefined,
  planBillingPeriod: PlanBillingPeriod
) => (planTier: PremiumPlanTier) =>
  // Show plan tiers that have the specified PlanBillingPeriod
  (planBillingPeriod === planTier.plan_billing_period &&
    (planTier.plan_type !== PlanType.free ||
      planTier.plan_id === activePlanTier?.plan_id)) ||
  // Always show enterprise plan
  planTier.plan_type === PlanType.enterprise ||
  // Show free plan card if user has no active plan
  (activePlanTier?.plan_type === planTier.plan_type &&
    planTier.plan_type === PlanType.free)

/**
 * This function returns whether the word is enabled for the user.
 * Check if the global feature flags is enabled, if it's not, then it's not enabled for all users
 * If it is enabled, then check if the user has the feature flag enabled, only if both global feature flags and user feature flags are enabled, then it's enabled for the user
 * @param user The user object
 * @param featureFlags The feature flags object
 * @returns Whether the word is enabled for the user
 */
export const isWordsEnabled = (user: User): boolean => {
  // Shopify users don't have access to words
  if (user.platform_type && user.platform_type === PlatformType.shopify) {
    return false
  }
  return Boolean(
    user?.service_configs?.feature_flags &&
      user?.service_configs?.feature_flags?.enable_words
  )
}

export const canAccessPlagiarismChecker = (
  premiumPlanTier?: PremiumPlanTierWithPlanType
) => {
  return !!(
    premiumPlanTier &&
    (premiumPlanTier.isTeamPlan ||
      premiumPlanTier.isEnterprisePlan ||
      premiumPlanTier.isUnlimitedPlan)
  )
}

/**
 * Given the unix timestamp (in gmt+0), calibrate the time to the local time
 * as determined by the browser and convert it to a readable datetime string for the user.
 * @param timestamp This is the unix timestamp from last_updated_at
 * @returns a formatted datetime string
 */
export const formatUnixTimeStamp = (timestamp: number): string => {
  return moment.unix(timestamp).toDate().toLocaleString(userLocale)
}
