import React, { useCallback } from 'react'

import { useHistory } from 'react-router-dom'

import automapper_logo from '../assets/v2/automapper.svg'

import { BaseToolCard, ToolCardWithMetadata } from './BaseToolCard'

const TITLE = 'AI Automapper'
const SUBTITLE = 'Transform any product data file into a structured CSV format.'
export const AUTOMAPPER_CARD_ID = 'automapper'

const AutomapperCard = () => {
  const history = useHistory()
  const onClick = useCallback(async () => {
    history.push('/automapper')
  }, [history])

  return (
    <BaseToolCard
      title={TITLE}
      subtitle={SUBTITLE}
      onClick={onClick}
      logoSrc={automapper_logo}
    />
  )
}

const metadata = {
  id: AUTOMAPPER_CARD_ID,
  searchTerms: [TITLE, SUBTITLE]
}

export const AutomapperCardWithMetadata: ToolCardWithMetadata = {
  card: <AutomapperCard />,
  metadata
}
